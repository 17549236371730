import React from "react";
import './ContentGroups.css'

const ContentGroups = () => {
    return (
      <div id="cs-content">
        <div className="cs-content">
          <div className="cs-flex-wrapper">
            <div className="cs-flex">
              <span className="cs-topper">My Mission</span>
              <h2 className="cs-title">Experience the Difference of One-on-One Service <p> </p> Personalized and Consistent Care, Every Time.</h2>
            </div>
            <a href="#" className="cs-button-solid">
              View My Services
            </a>
          </div>
          <div className="cs-wrapper">
            <p className="cs-text">
            Building personal relationships with my customers is at the heart of what I do. Through trusted work and exceptional service, I’ve earned a reputation for reliability. With over 10 years of industry expertise, you can have complete confidence that your pool is in expert hands.            </p>
            <ul className="cs-ul">
              {[
    "Your pool will always be safe and ready to enjoy.",
"Say goodbye to green water – crystal clear is my standard.",
"Chemicals perfectly balanced for optimal safety and comfort.",
"Skimmers cleaned for a pristine swimming experience.",
"Filters maintained to ensure peak performance and cleanliness."
              ].map((text, index) => (
                <li className="cs-li" key={index}>
                  <img
                    className="cs-check-icon"
                    aria-hidden="true"
                    loading="lazy"
                    decoding="async"
                    src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Icons/orange-check.svg"
                    alt="checkmark"
                    width="20"
                    height="20"
                  />
                  {text}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };
  
  export default ContentGroups;
  